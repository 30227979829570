<template>
  <div class="groups--content mt-10 px-4">
    <v-row>
      <v-col class="d-flex align-center" cols="12" sm="4">
        <v-text-field
          v-model="searchKey"
          placeholder="Search Group"
          :append-icon="icons.search"
          @input="search"
          hide-details
          clearable
          rounded
          filled
          flat
        />
      </v-col>
      <v-spacer />
    </v-row>

    <v-row class="mt-6">
      <v-col cols="12" sm="10">
        <v-simple-table fixed-header>
          <thead>
            <tr>
              <th class="text-left">Emoji</th>
              <th class="text-left">Name</th>
              <th class="text-left">Date Added</th>
              <th class="text-left">Date Updated</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <template v-if="!loading && hasGroups">
              <template v-for="group in groups">
                <tr :key="group.id">
                  <td class="text-center">
                    {{ group.emoji.value }}
                  </td>
                  <td>{{ group.name }}</td>
                  <td>
                    {{ group.created_at | formatedDate('MMMM DD, YYYY') }}
                  </td>
                  <td>
                    {{ group.updated_at | formatedDate('MMMM DD, YYYY') }}
                  </td>
                  <td class="text-right">
                    <v-menu
                      transition="scale-transition"
                      min-width="150"
                      :close-on-click="true"
                      offset-y
                      rounded
                      bottom
                      left
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon> {{ icons.dotsVertical }} </v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item link>
                          <v-list-item-title @click="editGroup(group)">
                            Edit
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item link>
                          <v-list-item-title
                            class="error--text d-flex"
                            @click.stop="deleteGroup(group)"
                          >
                            Delete
                            <v-spacer />
                            <v-progress-circular
                              v-if="deleting"
                              indeterminate
                              width="2"
                              size="20"
                            />
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </template>
            </template>

            <tr class="no-hover" v-if="loading">
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>

            <tr v-if="!loading && !hasGroups" class="no-hover">
              <td colspan="4">
                <div
                  class="d-flex align-center justify-center font-weight-bold body-2 grey lighten-4 mt-3 pa-6 w-full text-center"
                >
                  No predefined groups.
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Group from '@/models/Group'

import { mdiDotsVertical, mdiMagnify } from '@mdi/js'
import { debounce } from 'lodash'
import { mapMutations } from 'vuex'

export default {
  name: 'Groups',

  data() {
    return {
      deleting: false,
      loading: true,
      searchKey: '',

      icons: {
        search: mdiMagnify,
        dotsVertical: mdiDotsVertical,
      },
    }
  },

  computed: {
    groups() {
      return this.$store.state.groups.list
    },

    hasGroups() {
      return this.groups.length
    },
  },

  created() {
    this.fetchGroups()
  },

  methods: {
    ...mapMutations('groups', [
      'setGroupList',
      'clearGroupList',
      'setGroupDetails',
      'setGroupListMeta',
      'removeGroupOnList',
    ]),

    async fetchGroups() {
      this.loading = true

      try {
        const query = await Group.where('is_default', true)

        if (this.searchKey) {
          query.where('search', this.searchKey)
        }

        const result = await query.params({ limit: 50 }).get()

        this.setGroupList(result.data)
        this.setGroupListMeta(result.meta)

        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },

    search: debounce(function () {
      this.clearGroupList()
      this.fetchGroups()
    }, 600),

    editGroup(group) {
      this.setGroupDetails(group)
    },

    async deleteGroup(group) {
      this.deleting = true

      try {
        await group.delete()

        this.removeGroupOnList(group)
      } finally {
        this.deleting = false
      }
    },
  },
}
</script>
